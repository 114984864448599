// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-redirect-js": () => import("../src/templates/redirect.js" /* webpackChunkName: "component---src-templates-redirect-js" */),
  "component---src-templates-wars-tip-js": () => import("../src/templates/wars-tip.js" /* webpackChunkName: "component---src-templates-wars-tip-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ae-waiting-time-js": () => import("../src/pages/ae-waiting-time.js" /* webpackChunkName: "component---src-pages-ae-waiting-time-js" */),
  "component---src-pages-cases-js": () => import("../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-disruption-js": () => import("../src/pages/disruption.js" /* webpackChunkName: "component---src-pages-disruption-js" */),
  "component---src-pages-gov-news-js": () => import("../src/pages/gov-news.js" /* webpackChunkName: "component---src-pages-gov-news-js" */),
  "component---src-pages-high-risk-js": () => import("../src/pages/high-risk.js" /* webpackChunkName: "component---src-pages-high-risk-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-poster-gallery-js": () => import("../src/pages/poster-gallery.js" /* webpackChunkName: "component---src-pages-poster-gallery-js" */),
  "component---src-pages-shops-js": () => import("../src/pages/shops.js" /* webpackChunkName: "component---src-pages-shops-js" */),
  "component---src-pages-wars-tips-js": () => import("../src/pages/wars-tips.js" /* webpackChunkName: "component---src-pages-wars-tips-js" */),
  "component---src-pages-world-js": () => import("../src/pages/world.js" /* webpackChunkName: "component---src-pages-world-js" */)
}

