import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from "@/locales/en/translation.json"
import translationID from "@/locales/id/translation.json"

i18n.use(initReactI18next).init({
  lng: "id",
  fallbackLng: "en",
  debug: false,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      translation: translationEN,
    },
    id: {
      translation: translationID,
    },
  },
  react: {
    wait: true,
  },
  keySeparator: "-",
})

export default i18n
