module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-160735846-1","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Informasi Terpercaya COVID-19 di Indonesia | Transparansi data dari Komunitas Terpercaya","short_name":"COVID-19 in Indonesia","start_url":"/","background_color":"#1a237e","theme_color":"#1a237e","display":"standalone","icon":"static/images/goop_logo_website.jpg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
